// Capitolium
@font-face {
    font-family: 'Capitolium';
    src: url('/assets/minor/brands/fonts/38CC41_22_0.woff') format('woff');
    font-weight: bold;
    font-style:normal;
    font-display: swap;
}

// Gilroy Thin
@font-face {
    font-family: 'Gilroy';
    src: url('/assets/minor/brands/fonts/38CC41_13_0.woff') format('woff');
    font-weight: 100;
    font-style:normal;
    font-display: swap;
}

// Gilroy Ultralight
@font-face {
    font-family: 'Gilroy';
    src: url('/assets/minor/brands/fonts/38CC41_14_0.woff') format('woff');
    font-weight: 300;
    font-style:normal;
    font-display: swap;
}

// Gilroy Bold italic
@font-face {
    font-family: 'Gilroy';
    src: url('/assets/minor/brands/fonts/38CC41_14_0.woff') format('woff');
    font-weight: 700;
    font-style:italic;
    font-display: swap;
}

