$font-raleway: Gilroy, Arial, sans-serif;
$font-oswald: Capitolium, Arial, sans-serif;
$font-serif: Gilroy, Times, serif;

$font-title: $font-raleway;
$font-sub-title: $font-raleway;
$font-navigation:$font-raleway;
$font-header: $font-raleway;
$font-alternate-header: $font-raleway;
$font-body: $font-raleway;
$font-alternate-body:$font-oswald;
$font-cta:$font-oswald;
$font-small-text-size:$font-raleway;
$font-numeric:$font-raleway;

$font-size-h1:2.5em;
$font-size-h2:2.25em;
$font-size-h3:2em;
$font-size-h4:1.75em;
$font-size-h5:1.5em;
$font-size-h6:1.25em;

$base: 16; // 16px
$icomoon-font-path: "../icons/fonts";

// Variable settings for grids and what not

$site-width: 1600px;
$site-gutter: 20px;
$site-outer-gutter: 40px;
$text-width: 880px;

$hero-height: 600px;
$hero-min-height: 360px;


// Breakpoints
$bpSmallMobile: 384px;          // Used when the booking mask fills the screen, it needs to shrink from this point
$bpMaxMobile: 767px;
$bpMinDesktop: 1025px;
$bpMinLgDesktop: 1400px;
$bpMinTablet: 812px;

$bpShortWindowBookingMask: 700px;

$content-complex-buttons: 1200px;


// Booking mask
// $header-height: 113px;
$header-height: 190px;
$header-mobile-height: 152px;
$header-mobile-with-notice-height: 200px;
$horizon-header-mobile:36px;
$horizon-header-mobile-with-notice-height: 150px;
$horizon-header-mobile-with-notice-with-event-height: 190px;
$header-height--scrolled: 134px;
// $header-height--scrolled: 59px;
$header-height-desktop: 135px;
$header-height-desktop--active: 85px;
$header-height-desktop--scrolled: 105px;
$mask-max: 384px;
$mask-height: calc(100vh - #{$header-height-desktop--scrolled}); // Original: calc(100vh - #{$header-height});
$mask-height-desktop: calc(100vh - #{$header-height-desktop--scrolled}); // Original: calc(100vh - #{$header-height-desktop});
$mask-height-desktop--scrolled: calc(100vh - #{$header-height-desktop--scrolled});
$mask-height-desktop-with-notice: calc(100vh - 50px - #{$header-height-desktop--scrolled}); // Original: calc(100vh - #{$header-height-desktop})
$menu-height: calc(86vh - #{$header-height-desktop--scrolled});
$menu-height--scrolled: calc(86vh - #{$header-height--scrolled});
$mask-menu-height: 510px;

$notice-bar-height: 49px;
$horizon-header-toolbar-height: 65px;
$horizon-booking-mask-height: 85px;

// Transitions
$quick: .25s;
$medium: .4s;
$long: .8s;
$slow: 1s;
$headerAnimation: 1s;               // All header aninimation is relative to this time, being a fraction or multiple of it

// Easing
$easeOutExpo: cubic-bezier(.19, 1, .22, 1);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);
$headerEasing: $easeOutExpo;


// Colours

// Type/text
$t-gold: #350827;                               // Text by itself, icons (carousel arrows)
// $t-standard: #222;
$t-standard:#350827;

$t-standard-light: #350827;                       // Testimonials
$t-standard-faint: #8b8589;                        // Meta tags
$t-standard-on-gold: #e3e1dd;                   // Hover text for gold header

$f-icon-color: $t-standard-light;               // Icons/glyphs on the site

$t-red-faint: #e08e92;                          // Twitter meta tags
$t-blue-faint: #a6bcdd;                         // Facebook meta tags
$t-text-shadow: 0 0 10px #000;                  // Text shadow
$t-rate:#78ad49;                          // Text Rate color

$f-minimal-box-shadow: 0 -1px 0 transparent, 0 0 2px rgba(0,0,0,.12), 0 2px 4px rgba(0,0,0,.24);    // Google box shadow
$f-inset-box-shadow: inset 0px -8px 5px -5px rgba(0,0,0,0.2);
$f-inset-box-shadow-long: inset 0px -8px 10px -5px rgba(0,0,0,0.1);

// Fill / background
$f-gold: #350827;                               // Section bg, buttons, borders, section breaks
$f-gold-dark: darken(#350827, 17%);             // Gold button on gold
$f-gold-faint: #463541;                        // Gold border on dark backgrounds
$f-gold-header: rgba(53, 8, 39, 1);                 // Header scroll colour
$f-gold-overlay: rgba(53, 8, 39, .8);                   // Instagram overlay
$f-gold-op: rgba(53, 8, 39, 1);
$f-gold-invert: #463541;                        // Header button once scrolled
$f-pale-blue: #f1f0f1;                          // Press coverage
$f-pale-blue-tone:#f3f2ed;                      // Press releases
$f-op50-white: #f3f2ed;                         // White BG buttons
$f-op75-white: rgba(255,255,255,.75);           // White triangle on signatures
$f-menu-triangle-color:#fff;					//Menu Triangle (Arrow) Color
$f-main-background:#f1eff0;					//Background color of <main>

$f-grey-light: #F3F5F5;                            // Press releases border
$f-grey-faint: #f4f4f4;                         // Listing item cta section
$f-grey-light-border:#f1eff0;                     // Footer border
$f-grey-dark: #f7f6f3;                         // Header destination, section background
$f-grey-mid: #95979b;
$f-op50-grey-dark: rgba(88,89,91,.5);           
$f-op75-grey-dark: rgba(88,89,91,.75);          
$f-op50-logo-header: rgba(53, 8, 39,.5);           // Header background 
$f-op75-language-bar: rgba(53, 8, 39, .75);          // Header background 
$f-grey-dark-border: #333;                      // Header mobile border
$f-op35-grey-dark-border: rgba(51,51,51,.35);               // Header mobile border
$f-grey-border: #2b2b2d;                        // Footer border
$f-black-overlay: rgba(0,0,0,0.55);             // Gallery item, captions on images
$f-black-overlay-light: rgba(0,0,0,0.2);

//Element specific color
$f-text-reveal-cta-bg:$f-grey-faint;			//Grid item wrapper backgound on listing page

$f-red: #943236;                                // Twitter panel BG
$f-red-tone: #7f3033;                           // Twitter panel border
$f-blue: #395173;                               // FB panel BG
$f-blue-tone: #204970;                          // FB panel border
$f-gold-ol: rgba(113, 104, 53, .8);             // Instagram overlay hover

$f-header-bg-light: #dedede;                    // Mobile header langauge bg
$f-header-bg-light-op: #dedede;                 // Mobile header langauge bg
$f-header-bg-dark: darken(#dedede, 5%);                     // Mobile header btn and border
$f-header-bg-faint: #dedede;                    // Mobile header light border
$f-header-bg: #dedede;                         // Mobile header bg of logo section
$destination-overlay-bg: darken($f-grey-dark,4%); // Destination Overlay BG

$f-logo-black: #231f20;                         // Black and gold Anantara scheme

$t-error: #e00000;								// Accessible error colour
$t-warning: #e0bc20;							// Accessible warning colour

// Booking mask colours
$f-bm-button: white;	// white
$f-bm-button--scrolled:  $f-gold;	// $f-gold-invert
$f-bm-button--scrolled--hover: lighten($f-bm-button--scrolled, 5%);
$f-bm-arrow: $f-gold; // Arrows on booking mask
$t-bm-button: $t-gold;	// $t-gold

$f-bm-book-button: $f-gold;	// $f-gold
$f-bm-book-button--hover: darken($f-bm-book-button, 5%);

$f-bm-bg--primary: #F3F5F5;  //$t-standard-on-gold;	// $f-header-bg-light
$f-bm-bg--primary--hover: #F3F5F5;//$t-standard-on-gold;
$f-bm-border--primary: #cecece;	// $f-gold-faint

$f-bm-bg--secondary: #F3F5F5; //$t-standard-on-gold;	// $f-gold
$f-bm-bg--secondary--subtle: lighten($f-bm-bg--secondary, 17%);	// $f-gold-dark
$f-bm-bg--secondary--hover:   #F3F5F5;// $t-standard-on-gold;
$f-bm-border--secondary: #cecece;	// $t-gold

$f-bm-inputs: darken(#F3F5F5, 3% ); //#ededed;

$t-bm-on-primary: $t-standard;	// $t-gold
$t-bm-on-primary--alt: $t-standard;	// #fff
$t-bm-on-primary--subtle: $t-standard;	// $t-standard-faint

$t-bm-on-secondary: $t-standard;	// $t-standard

$t-bm-select2-placeholder: #FFF;	//Select2 placeholder text color

$-form-border: #b7b7b7;

//Section color
$section-gold-bg-color: $f-gold;
$section-gold-main-title-color:#fff;
$section-gold-sub-title-color:#fff;
$section-gold-text-color: #FFF;
$section-gold-link-color: #FFF;

$section-dark-grey-bg-color: $f-grey-dark;
$section-dark-grey-main-title-color: $t-gold;
$section-dark-grey-sub-title-color: $t-standard;
$section-dark-grey-text-color: unset;
$section-dark-grey-link-color: $f-gold;

$section-darker-grey-bg-color: $f-gold-invert;
$section-darker-grey-main-title-color:#fff;
$section-darker-grey-sub-title-color:#fff;
$section-darker-grey-text-color: #FFF;
$section-darker-grey-link-color: #FFF;

$section-light-grey-bg-color: $f-grey-light-border;
$section-light-grey-main-title-color: $t-gold;
$section-light-grey-sub-title-color: $t-standard;
$section-light-grey-text-color: unset;
$section-light-grey-link-color: $f-grey-dark;

$section-lighter-grey-bg-color: $t-standard-on-gold;
$section-lighter-grey-main-title-color: $t-gold;
$section-lighter-grey-sub-title-color: $t-standard;
$section-lighter-grey-text-color: unset;
$section-lighter-grey-link-color: $f-grey-dark;

$section-white-bg-color: #FFF;
$section-white-main-title-color: $t-gold;
$section-white-sub-title-color: $t-standard;
$section-white-text-color: unset;
$section-white-link-color: $f-grey-dark;

$section-blog-intro-bg-color: #f5f6f0;
$section-blog-intro-main-title-color:$t-gold;
$section-blog-intro-sub-title-color:$t-standard;
$section-blog-intro-text-color: unset;
$section-blog-intro-link-color: $f-grey-dark;

$section-blue-tone-bg-color: linear-gradient(90deg, $f-pale-blue-tone 0%, $f-pale-blue-tone 50%, #dadbde 50.001%, #dadbde 100%);
$section-blue-tone-main-title-color: $t-gold;
$section-blue-tone-sub-title-color: $t-standard;
$section-blue-bg-color:$f-pale-blue-tone;
$section-blue-tone-text-color: unset;
$section-blue-tone-link-color: $f-grey-dark;

//destination Menu
$t-menu-default-color:$t-standard;
$t-menu-default-color-hover:$t-standard-faint;
$t-menu-country-color:$t-gold;