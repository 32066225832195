body.avani {
    .section-video .full-bg {
        height: calc(100vh - 100px);
    }

    #callTA {
        border-color: $f-gold;
    }

    .button {
        &__submit {
            &:hover {
                background: #461035;
            }
        }
    }

    .btn {
        &--ghost {
            border-color: $f-gold;

            &:hover,
            &:focus {
                background: $f-gold;
                color: white;
            }
        }

        &--ghost.btn--gold {
            border-color: $f-gold;
            color: $f-gold;
        }

        &--dark {
            color: white;
            background-color: $f-gold;
        }
    }

    .myprivilege {
        .constraint {
            .mybooking {
                color: $t-gold;
                background: white;

                .input__field {
                    color: $f-gold !important;
                }
            }

            .myBookingPopupClose {
                color: $t-gold;
            }
        }
    }

    .btn--gold,
    .myprivilege .btn button,
    .btn-avani-purple {
        border: 1px solid transparent;

        &:hover {
            background: #461035;
            border-color: $f-gold;
            color: white;
        }
    }

    .btn--gold,
    .btn-avani-white {
        &-transparent {
            background: transparent;
            border: 1px solid $f-gold;

            &:hover{
                color: white;
                background: #461035;
            }
        }
    }

    .btn--rte-gold {
        border: 1px solid $f-gold;

        &:hover,
        &:focus {
            background: transparent;
            color: $f-gold !important;
        }
    }

    .t-c-m p > a.btn--rte-grey,
    .t-c-m p a.btn--rte-gold {
        border: 1px solid $f-gold !important;
    }

    .slinky-menu {
        background-color: $f-gold;

        @include desktop {
            background-color: transparent;
        }

        ul {
            li {
				&.active {
					a.menu__item__link {
                        border-bottom: 1px solid white;
                    }
				}
				
                a {
                    background-color: $f-gold;
                    border-bottom-color: $f-gold-faint;
                    @include desktop {
                        background-color: transparent;
                        border-bottom: 0;
                    }
                }
            }
        }

        .next {
            $triangle-size: 10px;
            &:after {
                @include border-left($triangle-size solid white);
            }

            &.has-link {
                background-color: $f-gold;
                border-bottom-color: $f-gold-faint;
                @include border-left(1px solid $f-gold-faint);
            }
        }

        .sub-menu__item {
            &.t-gold,
            &.rate__title-link {
                > a {
                    color: white;
                }
            }
        }
    }

    .site-container.no-banner {
        .header-primary--light-bg {
            .slinky-menu {
                ul {
                    > li {
                        > a {
                            &.active, &:hover, &:focus {
                                color: $f-gold-faint;
                            }
                        }
                    }
                }
            }

            .js-destination {
                &:hover{
                    &:after {
                        border-top-color: $f-gold-faint;
                    }
                }
            }
        }
    }

    .header-toolbar {
        background-color: $f-pale-blue-tone;

        @media screen and (max-width:1024px){
            background-color: $f-gold;
        }
    }

    .account-mobile {
        background-color: $f-gold;
        border-top: 1px solid $f-gold-faint;
        border-bottom: 1px solid $f-gold-faint;
    }

    .menu-mobile {
        background-color: $f-gold;
        @include border-right(1px solid $f-gold-faint);
    }


    .panel-currency .trigger,
    .language-selector .language-bar__link {
        color: $t-gold;
    }

    .panel-currency {
        .trigger {
           
            @include no-desktop {
                color: white;
            }
             
            &:after {
                border-top-color: $f-gold;

                @media screen and (max-width:1024px){
                    border-top-color: white;
                }
            }

            &.active:after {
                border-bottom-color: $f-gold;

                @media screen and (max-width:1024px){
                    border-bottom-color: white;
                }
            }
        }

        .currencyMenu {
            background: white;

            .currency_list {
                color: $t-gold;

                li {
                    &.active,
                    &:hover,
                    &:focus {
                        background: $f-gold;
                        color: white;

                        .currency-item .currency-code {
                            color: white;
                        }
                    }

                    .currency-item .currency-code {
                        line-height: 1;
                        float: none;
                    }
                }
            }
        }
    }

    .myprivilege .constraint #myProfile .menu {
        background: $f-op50-white;
        box-shadow: $f-minimal-box-shadow;

        a {
            color: $t-gold;

            &:hover {
                background-color: $f-gold;
                color: white;
            }
        }
    }

    .header-primary {
		background-color: $f-gold;

		@media screen and (min-width:1025px){
			background-color: transparent;
			box-shadow: none;
		}

        .header-inside {
            @media screen and (min-width:1025px){
                background-color: rgba(53, 8, 39, 1);
            }
        }

        .account__booking {
            .btn-internal {
                @include no-desktop {
                    color: $t-gold !important;
                }
            }
        }

        .account__booking > .btn {
            background-color: white;
            color: $t-gold !important;
            border: 0;

            &:hover,
            &:focus,
            &:visited {
                color: $t-gold !important;
            }
        }

        .header-inside {
            .is-active {
                .account__booking > .btn {
                    background-color: $f-gold;
                }
            }
        }

        &.is-scrolled {
            background-color: $f-gold;
			
			.account {
				@media screen and (max-width:1024px){
					top:0;
				}
			}

            .header-inside {
                background-color: transparent;

                .is-active {
                    .account__booking > .btn {
                        background-color: lighten($f-gold, 5%);
                        color: white;

                        @include mobile {
                            background-color: $f-gold;
                            color: white;
                        }


                        .btn-internal,
                        &:hover,
                        &:focus,
                        &:visited {
                            border-color: transparent !important;
                            color: white !important;
                        }
                    }
                }
            }

            .account__booking > .btn {
                background-color: white;
                color: $t-gold;
                
                .btn-internal,
                &:hover,
                &:focus,
                &:visited {
                    color: $t-gold !important;
                    border-color: white !important;
                }
            }
        }

        &--light-bg {
            .js-destination {
                &:hover{
                    &:after {
                        border-top-color: #55344b;
                    }
                }
            }
        }
    }

    .select2 {
        &-container {
            &--anantara {
                .select2 {
                    &-search {
                        &--dropdown {
                            &:after {
                                color: $f-gold;
                            }
                        }
                    }
                }
            }
        }
    }

    .slinky-menu {
        ul {
            > li {
                > a {
                    &.active, &:hover, &:focus {
                        color: $f-grey-light;

                        @include desktop {
                            .header-primary--light-bg & {
                                color: $f-gold-overlay;
                            }
                        }
                    }
                }
            }
        }

        .brand-home {
            > a {
                color: white;

                &:before {
                    @include border-right(8px solid white);
                }
            }
        }
    }

    // Section
    .section {
        &-footer {
            &__text {
                color: $t-gold;
            }

            .btn {
                background-color: $f-gold;
                color: white;

                &:hover {
                    background-color: #160310;
                    color: white !important;
                }
            }
        }

        &-category {
            &__form {
                .section__heading {
                    color: $f-gold;
                }
            }
        }

        &--blue-blue-tone {
            @include mobile {
                background: $f-pale-blue-tone;
            }
        }
    }

    .section--dark-grey,
    .section--light-grey {
        .slick {
            &-dots {
                li {
                    button {
                        &:before {
                            border-color: $f-gold;
                        }
                    }

                    &.slick-active button:before {
                        background-color: $f-gold;
                    }
                }
            }
        }
    }

    // Destination
    .destinations {
        background: $f-pale-blue-tone;

        a {
            color: $t-gold;
        }

        &__menu {
            &.has-cities > ul > li,
            > ul > li {
                .fallback-title {
                    color: #000;

                    &:hover,
                    &:focus {
                        color: $f-gold-faint;
                    }
                }

                // City Level
                > a {
                    &:hover, &:focus {
						color: $t-standard-faint;
					}

                    &.view-all {
                        &:hover, &:focus {
                            background: $f-op75-white;
                        }
                    }
                }

                // Resort level
				> ul > li {
                    > a {
                        &:hover, &:focus {
                            color: $t-standard-faint;
                        }
                    }

                    // Mini-Resort level
						> ul > li {
                            > a {
                                &:hover, &:focus {
                                    color: $t-standard-faint;
                                }
                            }
                        }
                }

                > a.view-all {
                    // background-color: black;
                    // color: white;
                }
            }
        }

        .js-destinations__close {
            background: black;
        }
    }

    .section--white{
        .destinations {
            a {
                color: $t-gold;
            }

            &__menu {
                > ul > li {
                    > a,
                    .fallback-title {
                        color: #000;

                        &:hover,
                        &:focus {
                            color: $t-standard-light;
                        }
                    }
                }
            }
        }
    }

    .header-primary.is-scrolled .destinations {
        background: $f-pale-blue-tone;
    }

    // Destination Explorer Overlay
    .destination-explorer {
        .full-bg {
            img {
                display: none;
            }

            &.full-bg--has-overlay {
                color: $t-gold;

                &:before {
                    background-color: rgba(0, 0, 0, 0);
                }

                .title {
                    color: $t-gold !important;
                }

                .destinations__menu {
                    color: $t-gold;
                }
            }

            &__overlay {
                background: linear-gradient(to bottom,$f-pale-blue-tone 50%,$f-pale-blue-tone 100%);
            }
        }
    }

    // Play button
    .intro-video__play {
        background-color: rgba(255, 255, 255, 0.6);

        &::before {
            border-color: transparent transparent transparent $f-gold-overlay;
        }
    }

    // Fancybox
    .fancybox-close .close-btn {
        background-color: $f-gold;
    }

    // Awards
    .awards {
        .t-underline:after {
            background-color: $f-gold;
        }
    }

    // Full breed
    .news-container--feature,
    .news-container {
        .full-bg {
            .section-header{
                &__title {
                    color: white;
                }
            }
        }
    }

    .full-bg{
        &__overlay {
            background: linear-gradient(to bottom, transparent 50%, rgba(0,0,0, 0.6) 100%);
            z-index: 0;
        }

        .section-header__title {
            color: white;
        }

        .testimonial {
            .section-header{
                &__title {
                    color: $t-gold;
                }
            }
        }

        .awards {
            .section-header{
                &__title {
                    color: $t-gold;
                    font-weight: 600;
                }
            }
        }

        .section{
            &-footer__text {
                color: white;
            }
        }

        .contact-inline {
            color: white;

            .t-gold,
            strong {
                color: white !important;
            }

            .t-underline:after {
                background-color: white;
            }

            .btn--gold.btn--ghost {
                border: 1px solid white;
                color: white !important;

                &:hover {
                    background-color: white;
                    color: $t-gold !important;
                }
            }
        }

        .slick {
            &-dots {
                li {
                    button {
                        &:before {
                            border-color: white !important;
                        }
                    }

                    &.slick-active button:before {
                        background-color: white!important;
                    }
                }
            }
        }
    }

    // Experience
    .video-cta {
        &__heading {
            color: white !important;
        }

        &--full-height {
            .video-cta {
                &__heading {
                    font-size: 1.8em;
                }

                &__title {
                    font-size: 2.5em;
                }
            }
        }

        &__title{
            color: white !important;
        }

        &__link:before {
            border: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255,255, 0.3);
            z-index: 0;
        }
    }

    // Triangle
    .triangle{
        &-cta,
        &-cta--odd {
            &:before {
                border: 0;
                width: 100%;
                background: rgba(255, 255, 255, 0.6);

                @include mobile {
                    right: 0;
                    left: 0;
                    margin-left: 0;
                }
            }

            // &__text {
            //     @include mobile {
            //         bottom: 50%;
            //         transform: translateY(50%);
            //     }
            // }
        }
    }

    // Text reveal cta
    .text-reveal{
        .text-reveal__wrapper{
            
            .text-cta{
                color:#fff;
                @include margin-left(-14px);

                &--more-details:before {
			        @include border-left(20px solid white);
                }
            }
        }

        &__title {
            font-weight: 500;
        }

        .currency-converter select {
            border-bottom-color: white;
        }

        .btn--ghost {
            border-color: #fff;

            &:hover{
                border-color: #461035;
                background-color: #461035;
            }
        }

        &__bookingBenefits {
            color: #fff;

            &Title {
                color: #fff;
            }
        }

        // You may also like
        .t-underline:after {
            background-color: white;
        }
    }

    .compare-list {
        &__heading {
            font-weight: bold;
            color: white !important;
        }
    }

    .dynamic-list, .static-list {
        &__item {
            &.is-card__display {
                .t-underline:after {
                    background-color:  $f-gold;
                }

                .text-reveal{
                    &__bookingBenefits {
                        color: $t-gold;

                        &Title {
                            color: $t-gold;
                        }
                    }
                }

                .btn--ghost {
                    border-color: $f-gold;

                    &:hover,
                    &:focus {
                        background-color: $f-gold;
                    }
                }

                .currency-converter select {
                    border-bottom-color: $f-gold;
                }
            }
        }
    }

    .text-reveal.is-card__display {
        .t-underline:after {
            background-color:  $f-gold;
        }

        .text-reveal{
            &__bookingBenefits {
                color: $t-gold;

                &Title {
                    color: $t-gold;
                }
            }
        }

        .btn--ghost {
            border-color: $f-gold;

            &:hover,
            &:focus {
                background-color: $f-gold;
            }
        }

        .currency-converter select {
            border-bottom-color: $f-gold;
        }
    }

    .content-intro {
        .features-special {
            border-top-color: $f-header-bg-light;
        }
    }
	
	.content-intro-complex__text {
		.text-reveal{
            &__bookingBenefits {
                color: $t-gold;

                &Title {
                    color: $t-gold;
                }
            }
        }
	}

    .privilege-discount {
        border-top-color: $f-header-bg-light;
        border-bottom-color: $f-header-bg-light;
    }

    .other-offers {
        @include mobile {

            .text-reveal {
                &__title.t-underline {
                    &:after {
                        background-color: $f-gold;
                    }
                }

                &__text {
                    color: $t-gold;
                }

                .text-reveal__wrapper {
                    .text-cta {
                        color: $t-gold;

                        &--more-details {
                            &:before {
                                @include border-left(29px solid $f-gold);
                            }
                        }
                    }


                }

                .currency-converter {
                    .form {
                        .form-main,
                        .trigger {
                            &:after {
                                border-top-color: $f-gold;
                            }
                        }
                    }

                    select {
                        border-bottom-color: $f-gold;
                        color: $t-gold;
                    }
                }
            }
        }
    }

    .offers--only-one .text-reveal {
        background: $f-gold;
    }

    // Social Media
    .olapic #olapic-slider-widget .olapic-carousel .olapic-cta-item {
        background: $f-gold;
    }

    // Footer
    footer{
        background: $f-gold;

        .footer {
            &-middle {
                background: $f-gold;
                border-top-color: $f-gold;
            }

            &-hotels {
                background: $f-gold;
                .footer-brand__item {
                    background: $f-gold !important;
                }
            }

            &-bottom {
                background: $f-gold;
                border-top-color: white;
            }

            &-detail-links a {
                color: white;
            }
        }

    }

    .copyright {
        color: white;
    }

    .newsletter {
        .input-text {
            border: 1px solid white;
            @include border-right(0)
        }

        .btn-submit {
            border: 1px solid white;
            @include border-left(0);

            &:before {
                @include border-left(15px solid white);
            }
        }
    }

    // Dynamic-list
    .dynamic-list__pagination,
    .static-list__pagination {
        & > li {
            &.active {
                background: $f-gold;

                a {
                    color: white;
                }

                &:hover,
                &:focus {
                    background: $f-gold-overlay;
                }
            }


            &:hover,
            &:focus {
                background: $f-grey-dark;
            }
        }
    }

    // Meeting
    .tablesaw-cell-content {
        .t-s {
            font-weight: 300;
            letter-spacing: 1px;
        }
    }

    // Carousel Tab
    .carousel-tabs {
        &__item {
            &.is-active {
                .carousel-tabs__cat {
                    background: $f-gold;
                }
            }

            .carousel-tabs__cat {
                background: $t-gold;
                color: white;
            }
        }

        &__nav {
            .carousel-tabs__cat {
                &:before {
                    background: $f-pale-blue;
                }

                &.is-active {
                    &:hover,
                    &:focus {
                        &:before {
                            background: $f-gold;
                        }
                    }

                    &:before {
                        background: $f-gold;
                    }

                    &:after {
                        border-top-color: white;
                    }
                }

                &:hover,
                &:focus {
                    &:before {
                        background: $t-standard-faint;
                    }
                }
            }
        }

        &__content {
            background: transparent;

            @include mobile {
                color: $t-standard;
                background: $f-pale-blue;
            }
        }

        .features-special {
            [class^='glyph'] {
                @include mobile {
                    color: $f-gold;
                }
            }
        }

        .gallery,
        .gallery-slide {
            & ~ .carousel-tabs__description,
            .gallery-slide__text {
                background-color: $f-pale-blue-tone;

                @include mobile {
                    box-shadow: none;
                }
            }

            .slick-next {
                &:before {
                    @include border-left(20px solid $f-gold);
                }
            }

            .slick-prev {
                &:before {
                    @include border-right(20px solid $f-gold);
                }
            }

            .slick {
                &-dots {
                    li {
                        button {
                            &:before {
                                @include no-desktop {
                                    border-color: $f-gold;
                                }
                            }
                        }

                        &.slick-active button:before {
                            @include no-desktop {
                                background-color: $f-gold;
                            }
                        }
                    }
                }
            }
        }
    }

    // Gallery-slide
    .gallery-slide {
        .btn--ghost {
            color: $t-gold;
        }
    }


    // rte-block
    .rte-block{
        &__heading {
            &.t-underline:after{
                background: $f-gold;
            }
        }
    }

    // Static Listing
    .static-list {
        &__image {
            .t-underline:after {
                background-color: white;
            }
        }
    }

    // Litepicker
    .litepicker {
        .container__days {
            .day-item {
                &.is-in-range {
                    background-color: $f-grey-light-border;
                }
            }
        }
    }

    // Meeting Room
    .meeting-room {
        background-color: white;
    }

    // contact-inline
    .contact-inline {
        .contact-inline__list {
            &.is-dark {
                a {
                    color: $t-standard !important;
                }
            }
        }
    }

    // Travel advisory
    .notification {
        border-color: $-form-border;

        &-header {
            // font-family: $font-raleway;
            font-weight: 600;
        }
    }

    // accordian
    .accordion {
        &__title {
            .t-s {
                font-weight: 600;
            }
        }
    }

    // Policy Item
    .policy-item  {
        .t-xs {
            font-weight: 600;
            letter-spacing: 0;
        }
    }

    // Info popup
    .inline-popup table tr th {
        color: $f-gold;
        font-weight: 600;
    }

    .rate {
        .price_label.member {
            background: $f-gold;
        }

        .price_container {
            .option {
                border-color: $f-grey-mid;
            }
        }
        
        .discovery-dollar-banner {
            border-color: $f-grey-mid;
        }

        .features-special {
            .feature__item {
                &.non-refundable {
                    color: $t-standard-faint;
                }
            }
        }
    }

    .current-search {
        &__summary {
            .summary-item {
                .item_title {
                    color: $t-standard-faint;
                    
                    .glyph {
                        color: $t-standard-faint;
                    }
                }
            }
        }
    }

    // table
    table thead th {
        color: white;
    }

    // Social Share
    .social-share {
        &__title {
            font-weight: 600;
        }
    }

    // Faq
    .faq {
        .accordion {
            &__title {
                &:hover {
                    background: $f-grey-dark;
                }
            }
        }
    }
    // Vimeo
    .section-mobile-feature-video {
        .vimeo__title {
            text-align: center;
            max-width: 90%;
        }
    }

    // Location
    .location-map {
        &-listing {
            // background: $f-gold;
            // color: white;
            background: white;
            color: $t-gold;

            .coordinates {
                &-group a,
                .label {
                    // color: white !important;
                }
            }
        }

        &-list {
            > li {
                border-bottom-color: $t-standard-on-gold;
            }
        }

        ul {
            li {
                .col-2 {
                    color: $t-standard-faint;
                }
            }
        }

        .main-content {
            border-bottom-color: $t-standard-on-gold;
        }

        .information-content {
            .information__header {
                background: $t-standard;
            }
        }
    }

	form:not(.section-category__form, #booking-mask-form, #booking-mask-form--fmb) {
		select {
			optgroup {
				color: $f-grey-mid;
			}

			option {
				color: $t-standard;
				font-size: 1em;
			}
		}

		&.RFP {
			select {
				optgroup {
					color: $f-grey-mid;
				}

				option {
					color: $t-standard;
					font-size: 1em;
				}
			}
		}
	}

    // Dynamic map
    .dynamic-map {
        &.is-destination {
            .map-custom-control {
                .map-custom-button{
                    &:hover {
                        background: $f-gold;
                    }
                }
            }
        }
    }

    // Text-listing
    .text-listing {
        &__item {
            border-bottom-color: $f-grey-border;
        }

        &__meta {
            color: black;
        }
    }

	.inline-popup {
		.js-close {
			color:$f-gold;
		}
	}

    // Tripadvisor
    .trip-advisor__link {
        &:hover {
            text-decoration: none !important;
        }
    }
	
	.countdown {
		&--banner {
			.time {
				box-shadow: 1px 1px $f-grey-mid;
			}
		}
	}
	
	//Direct booking benefit
	
}
